<template>
    <div>
        <img v-for="(card, cardIndex) in playerHand.cards"
             :key="cardIndex"
             :class="{ card__doubled: playerHand.isDoubled }"
             :src="getCardImage(card)"
             :style="getPlayerCardPosition(cardIndex, playerHand.place)"
             class="card"
        />

        <div v-if="playerHand.isInsuranced"
             :style="getInsuranceChipPosition()"
             class="bet__wrapper"
        >
            <img :src="insuranceImage"/>

            <span class="insurance__text">
                {{ fromCents(playerHand.insuranceBet) }}
            </span>
        </div>

        <!-- Отрисовка очков игрока -->
        <div :style="getPlayerPointsPosition()"
             class="points__wrapper"
        >
            <img :src="pointsImage"
                 class="points__bg"
            />

            <span class="points__text">
                <template v-if="playerHand.isBlackJack">
                    BJ
                </template>
                <template v-else>
                    <template v-if="playerHand.secondValue !== 0">
                         {{ playerHand.secondValue }} /
                    </template>
                     {{ playerHand.firstValue }}
                </template>
            </span>
        </div>

        <!-- Отрисовка ставок игрока -->
        <div :style="getPlayerBetPosition()"
             class="bet__wrapper"
        >
            <img :src="coloredChipImage(playerHand.totalBet)"
                 class="bet__bg"
            />
            <span class="bet__text">
                   {{ fromCents(playerHand.isEvenMoney ? playerHand.totalBet * 2 : playerHand.totalBet) }}
                </span>
        </div>

        <!-- Отрисовка результата игрока -->
        <div v-if="playerHand.isEnd || isSpinEnd"
             :style="getPlayerResultPosition(playerHand.place)"
             class="result__wrapper"
        >
            <template v-if="playerHand.win > 0">
                <img :src="winResultImage()"
                     class="result__bg"
                />
                <span class="result__text">
                    {{ fromCents(playerHand.win) }}
                </span>
            </template>
            <template v-else-if="!playerHand.isBlackJack">
                <img :src="loseResultImage()"
                     class="result__bg"
                />
                <span class="result__text">
                    {{ fromCents(playerHand.totalBet) }}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import {fromCents} from "../../../../../helpers/balanceConverter";

export default {
    props: {
        playerHand: {
            required: true,
            type: Object,
            default() {
                return {
                    bet: null,
                    totalBet: null,
                    win: null,
                    firstValue: null,
                    secondValue: null,
                    isEnd: null,
                    isDoubled: null,
                    isInsuranced: null,
                    isEvenMoney: null,
                    insuranceBet: null,
                    cards: [{
                        cost: null,
                        suit: null,
                        firstValue: null,
                        secondValue: null
                    }],
                }
            }
        },
        isSpinEnd: {
            required: true,
            type: Boolean,
        },
        denom: {
            required: true,
            type: Number,
        }
    },
    data() {
        return {
            cardView: 30,
            card: {
                width: 90,
                height: 120,
            },
            betChip: {
                size: 55
            },
            insuranceChip: {
                size: 55
            }
        }
    },
    computed: {
        pointsImage() {
            return require('@/assets/games/blackjack/blackjack/points_bg.png');
        },
        insuranceImage() {
            return require('@/assets/games/blackjack/blackjack/insurance-chip.png');
        },
    },
    methods: {
        fromCents,
        getCardImage(card) {
            let imageName = `${card.cost}${card.suit}`;
            return require(`@/assets/games/blackjack/blackjack/cards/${imageName}.png`);
        },
        coloredChipImage(bet) {
            bet /= this.denom;

            switch (true) {
                case bet <= 3:
                    return require('@/assets/games/blackjack/blackjack/chip_red.png');
                case bet <= 7:
                    return require('@/assets/games/blackjack/blackjack/chip_green.png');
                case bet <= 9:
                    return require('@/assets/games/blackjack/blackjack/chip_blue.png');
                case bet <= 19:
                    return require('@/assets/games/blackjack/blackjack/chip_purple.png');
                default:
                    return require('@/assets/games/blackjack/blackjack/chip_gray.png');
            }
        },
        loseResultImage() {
            return require('@/assets/games/blackjack/blackjack/lose_bg.png');
        },
        winResultImage() {
            return require('@/assets/games/blackjack/blackjack/win_bg.png');
        },
        getPlayerCardPosition(cardNumber) {
            let top = 0;
            let left = this.cardView * cardNumber;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        },
        getPlayerBetPosition() {
            let left = 0, top = 0;

            left = this.cardView;
            top = this.card.height;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        },
        getPlayerResultPosition() {
            let left = 0, top = 0;

            left = this.cardView + this.betChip.size;
            top = this.card.height + this.betChip.size * 0.5;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        },
        getInsuranceChipPosition() {
            let left = 0, top = 0;

            left = this.cardView + this.insuranceChip.size;
            top = this.insuranceChip.size;

            return {
                left: `${left}px`,
                top: `-${top}px`,
            };
        },
        getPlayerPointsPosition() {
            let left = 0, top = 0;

            left = this.cardView + this.betChip.size;
            top = this.card.height;

            return {
                left: `${left}px`,
                top: `${top}px`,
            };
        }
    }
}
</script>

<style scoped>
.card {
    position: absolute;
    width: 90px;
    height: 120px;
    background: transparent;
    border: 0;
}

.card__doubled:last-child {
    transform: rotate(90deg);
    margin-left: -15px;
    margin-top: 15px;
}

.bet__wrapper {
    position: absolute;
    width: 55px;
    height: 55px;
    background: transparent;
}

.bet__bg {
    width: 55px;
    height: 55px;
}

.bet__text {
    font-weight: bold;
    font-size: 11px;
    color: white;
    cursor: default;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.points__wrapper {
    position: absolute;
    width: 51px;
    height: 30px;
    background: transparent;
}

.points__text {
    font-weight: bold;
    font-size: 12px;
    color: white;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.insurance__text {
    font-weight: bold;
    font-size: 12px;
    color: white;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.result__wrapper {
    position: absolute;
    width: 51px;
    height: 30px;
    background: transparent;
}

.result__bg {
    width: 51px;
    height: 30px;
}

.result__text {
    font-weight: bold;
    font-size: 11px;
    color: white;
    cursor: default;
    white-space: nowrap;

    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>
